@import 'styles/variables.less';

.officialReasonText > span > span > div {
  display: inline;
}
.divider {
  margin-bottom: 24px;
}

.bookmarkItemInfo {
  svg {
    fill: @shopping-cart-inactive;
    margin-right: 5px;
    vertical-align: middle;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);