.flexContainer {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-weight: bold !important;
  font-size: 64px !important;
  text-align: center;
  margin-bottom: 64px !important;
}

.text:not(:last-child) {
  margin-bottom: 32px;
}

.text {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}

.button {
  margin: 0px;
  padding: 0px;
  font-size: 24px;
  font-weight: bold;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.nextTry {
  font-size: 16px;
  text-align: center;
  margin-top: 32px;
  opacity: 0.5;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);