.addButton {
  display: flex !important;
  align-items: top !important;
  padding: 0 !important;
  text-align: left !important;
}

.icon {
  margin-top: 4px;
  margin-right: 8px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);