@import 'styles/variables.less';

.infos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1 auto;
  flex-wrap: wrap;
  align-content: stretch;
  h3 {
    font-size: 16px;
    font-weight: 400;
    display: inline;
    overflow-wrap: break-word;
    word-break: break-word;
    margin-right: 8px;
  }
  p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    margin-bottom: 0px;
  }
}
.modalButton {
  padding: 0;
  border: none;
  background-color: transparent;
  .modalButtonIcon {
    color: rgba(0, 0, 0, 0.5) !important;
    font-size: 18px;
  }
}
.modalButton:focus {
  border: none;
  outline: none;
}
.icon {
  color: var(--primary-color) !important;
  margin-right: 10px;
  font-size: 21px;
  vertical-align: top !important;
  line-height: 25.144px !important;
  svg {
    vertical-align: -0.15em !important;
  }
}
.priceInfos {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 1 auto;
}
.price {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 2px;
  color: rgba(0, 0, 0, 0.8);
}
.bindingness {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}
.officialReasonText {
  font-style: italic !important;
  color: rgba(0, 0, 0, 0.5) !important;
  text-align: right !important;
  display: inline-flex;
  gap: 4px;
}
.serviceEntry {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 16px 0px;
}
.serviceEntry:last-child {
  margin-bottom: 0px;
}

.minPriceInfo {
  color: @gray-7;
}

.minPriceInfoRightAligned {
  text-align: right;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);