.benefits {
  margin-top: 15px;
}

.benefitsText {
  font-size: 14px;
}

.headline {
  font-weight: 600;
}

.price {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.priceInfo {
  margin-top: -8px;
  min-height: 8px;
}

.inputFieldValues {
  margin-top: 16px;
}

.tenantPopover {
  max-width: 500px;
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);