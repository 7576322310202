.alert {
  background-color: var(--very-light-primary-color) !important;
  border-color: var(--primary-color) !important;
  :global(.anticon) {
    vertical-align: baseline !important;
    line-height: 21px !important;
  }
  span svg {
    color: var(--primary-color) !important;
    vertical-align: baseline !important;
  }
  ul {
    margin-bottom: 0px !important;
    padding-left: 22px !important;
  }
}

@font-size-base: 16px;@btn-primary-color: var(--btn-primary-color);